export const getItems = (wishlist) => wishlist.items_v2.items;
export const getItemName = (product) => { var _a; return ((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.name) || ''; };
export const getItemImage = (product) => { var _a; return ((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.thumbnail.url) || ''; };
export const getItemPrice = (product) => {
    var _a, _b, _c;
    let regular = 0;
    let special = null;
    if ((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.price_range) {
        regular = (_b = product === null || product === void 0 ? void 0 : product.product) === null || _b === void 0 ? void 0 : _b.price_range.minimum_price.regular_price.value;
        const final = (_c = product === null || product === void 0 ? void 0 : product.product) === null || _c === void 0 ? void 0 : _c.price_range.minimum_price.final_price.value;
        if (final < regular) {
            special = final;
        }
    }
    return {
        regular,
        special,
    };
};
export const getItemQty = (product) => product.quantity;
export const getItemAttributes = (_product, _filterByAttributeName) => ({ '': '' });
export const getItemSku = (product) => { var _a; return ((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.sku) || ''; };
export const getTotals = (wishlist) => {
    var _a, _b, _c;
    if (Array.isArray(wishlist)) {
        return (_b = (_a = wishlist[0]) === null || _a === void 0 ? void 0 : _a.items_v2) === null || _b === void 0 ? void 0 : _b.items.reduce((acc, curr) => ({
            total: acc.total + getItemPrice(curr).special,
            subtotal: acc.subtotal + getItemPrice(curr).regular,
        }), ({ total: 0, subtotal: 0 }));
    }
    return (_c = wishlist === null || wishlist === void 0 ? void 0 : wishlist.items_v2) === null || _c === void 0 ? void 0 : _c.items.reduce((acc, curr) => ({
        total: acc.total + getItemPrice(curr).special,
        subtotal: acc.subtotal + getItemPrice(curr).regular,
    }), ({ total: 0, subtotal: 0 }));
};
export const getShippingPrice = (_wishlist) => 0;
export const getTotalItems = (wishlist) => {
    var _a;
    return (Array.isArray(wishlist)
        ? (_a = wishlist[0]) === null || _a === void 0 ? void 0 : _a.items_count
        : ((wishlist === null || wishlist === void 0 ? void 0 : wishlist.items_count) || 0));
};
const getPagination = (wishlistData) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        currentPage: ((_b = (_a = wishlistData === null || wishlistData === void 0 ? void 0 : wishlistData.items_v2) === null || _a === void 0 ? void 0 : _a.page_info) === null || _b === void 0 ? void 0 : _b.current_page) || 1,
        totalPages: ((_d = (_c = wishlistData === null || wishlistData === void 0 ? void 0 : wishlistData.items_v2) === null || _c === void 0 ? void 0 : _c.page_info) === null || _d === void 0 ? void 0 : _d.total_pages) || 1,
        totalItems: (wishlistData === null || wishlistData === void 0 ? void 0 : wishlistData.items_count) || 0,
        itemsPerPage: ((_f = (_e = wishlistData === null || wishlistData === void 0 ? void 0 : wishlistData.items_v2) === null || _e === void 0 ? void 0 : _e.page_info) === null || _f === void 0 ? void 0 : _f.page_size) || 10,
        pageOptions: [10, 50, 100],
    });
};
const getProducts = (wishlistData) => {
    var _a;
    if (!wishlistData || (Array.isArray(wishlistData) && wishlistData.length === 0)) {
        return [];
    }
    const reducer = (acc, curr) => {
        var _a, _b;
        return [...acc, ...(_b = (_a = curr === null || curr === void 0 ? void 0 : curr.items_v2) === null || _a === void 0 ? void 0 : _a.items.map((item) => ({
                product: item.product,
                quantity: item.quantity,
                added_at: item.added_at,
                id: item.id,
            }))) !== null && _b !== void 0 ? _b : []];
    };
    const mapper = (item) => ({
        product: item.product,
        quantity: item.quantity,
        added_at: item.added_at,
        id: item.id,
    });
    return Array.isArray(wishlistData)
        ? wishlistData.reduce((acc, curr) => reducer(acc, curr), [])
        : (_a = wishlistData === null || wishlistData === void 0 ? void 0 : wishlistData.items_v2) === null || _a === void 0 ? void 0 : _a.items.map((e) => mapper(e));
};
const wishlistGetters = {
    getTotals,
    getShippingPrice,
    getItems,
    getItemName,
    getItemImage,
    getItemPrice,
    getItemQty,
    getItemAttributes,
    getItemSku,
    getTotalItems,
    getPagination,
    getProducts,
};
export default wishlistGetters;
