import { defineComponent } from '@nuxtjs/composition-api';
import { SfHeading } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
export default defineComponent({
    name: 'EmptyWishlist',
    components: {
        SfHeading,
        SvgImage,
    },
});
